import React from "react";
import Pin from "src/assets/images/pin.png";
import Img from "gatsby-image";

import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

import mapStyles from "src/components/Contact/mapStyle.js";

function Map() {
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 52.08278467244178, lng: 20.9676777344019 }}
      defaultOptions={{ styles: mapStyles }}
    >
      <Marker
        position={{ lat: 52.08278467244178, lng: 20.9676777344019 }}
        icon={{
          url: Pin,
          scaledSize: { width: 27.73, height: 23.43 },
        }}
      />
    </GoogleMap>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function App() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCizuqvzVgaIP0xu7_KM7TMMcyMpB7Q2LY`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
