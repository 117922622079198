import * as React from "react";
import styled from "styled-components";

import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/typography/BodyText.js";
import BigTitle from "src/components/typography/BigTitle.js";
import SEO from "src/components/SEO.js"

import mediaQuery from "src/assets/styles/mediaQuery";

import Map from "src/components/Contact/Map.js";

import BtnArrowSvg from "src/assets/svg/btn-arrow.svg";

const Header = styled(Wrapper)`
  padding-top: 210px;
  display: flex;
  align-content: stretch;
  border-bottom: solid 1px ${colors.midGrey};
  margin-bottom: 60px;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-top: 110px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 14.559vw;
    border-bottom: solid 0.0693vw ${colors.midGrey};
    margin-bottom: 4.159vw;
  }
`;

const MapWrapper = styled.div`
  height: 25vw;

  @media (max-width: ${mediaQuery.tablet}) {
    height: 60vw;
    width: calc(100% - 40px);
    margin-left: 20px;
  }
`;

const HeaderInfo = styled.div`
  width: 50%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const Street = styled(BigTitle)`
  @media (max-width: ${mediaQuery.tablet}) {
    width: 140px;
  }
`;

const HeaderRightCol = styled.div`
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const HeaderSubText = styled(BodyText)`
  color: ${colors.mainColor};
  margin-bottom: 10px;
  margin-top: 36px;
  @media (max-width: ${mediaQuery.tablet}) {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 0.693vw;
    margin-top: 2.495vw;
  }
`;

const MailWrapper = styled(BodyText)`
  margin-bottom: 40px;
  text-decoration: none;
  a {
    text-decoration: none;
  }
  span {
    color: ${colors.mainColor};
    margin-right: 20px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 2.773vw;
    span {
      color: ${colors.mainColor};
      margin-right: 1.386vw;
    }
  }
`;

const ContactRow = styled.div`
  display: flex;
  margin-bottom: 60px;
  &.mobile {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 30px;

    &.mobile {
      display: flex;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 4.159vw;
  }
`;

const ContactRowName = styled(BodyText)`
  color: ${colors.mainColor};
  width: calc(50% - 20px);
  text-align: right;
  margin-right: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 25%;
    text-align: left;
    flex-shrink: 0;
    &.noMobile {
      display: none;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(50% - 1.386vw);
    margin-right: 1.386vw;
  }
`;

const ContactRowInfo = styled(BodyText)`
  margin-right: 50px;
  a {
    text-decoration: none;
    display: block;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-right: 3.466vw;
  }
`;

const ContactRowInfo2 = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    ${ContactRowInfo} {
      margin-bottom: 30px;
      :last-of-type {
        margin-bottom: 0px;
      }
    }
  }
`;

const HoursPosWrapper = styled.div`
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 5.546vw;
  }
`;

const HoursPos = styled.div`
  margin-bottom: 20px;
  :last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 1.386vw;
  }
`;

const HoursText = styled(BodyText)``;

class KontaktPage extends React.Component {
  render() {
    return (
      <>
       <SEO title={this.props.data.strapiContactPage.SEO.Title}
        description={this.props.data.strapiContactPage.SEO.Description}/>
        <Header
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <HeaderInfo>
            <Street>{this.props.data.strapiContactPage.Title}</Street>
            <MailWrapper>
              <span>Mail: </span>
              <a
                href={`mailto:${this.props.data.strapiContactInformation.Mail}`}
              >
                {this.props.data.strapiContactInformation.Mail}
              </a>
            </MailWrapper>
          </HeaderInfo>
          <HeaderRightCol>
            <HeaderSubText>
              {this.props.data.strapiContactPage.SubTitle}
            </HeaderSubText>

            <MapWrapper>
              <Map />
            </MapWrapper>
          </HeaderRightCol>
        </Header>
        <Wrapper>
          <ContactRow className="mobile">
            <ContactRowName
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              Mail:
            </ContactRowName>

            <ContactRowInfo
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <a
                href={`mailto:${this.props.data.strapiContactInformation.Mail}`}
              >
                {this.props.data.strapiContactInformation.Mail}
              </a>
            </ContactRowInfo>
          </ContactRow>
          <ContactRow
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ContactRowName>Właściciel:</ContactRowName>
            <ContactRowInfo2>
              <ContactRowInfo>
                {this.props.data.strapiContactPage.Owner_1_name}
                <a
                  href={`tel:${this.props.data.strapiContactPage.Owner_1_tel}`}
                >
                  {this.props.data.strapiContactPage.Owner_1_tel}
                </a>
              </ContactRowInfo>
              <ContactRowInfo>
                {this.props.data.strapiContactPage.Owner_2_name}
                <a
                  href={`tel:${this.props.data.strapiContactPage.Owner_2_tel}`}
                >
                  {this.props.data.strapiContactPage.Owner_2_tel}
                </a>
              </ContactRowInfo>
            </ContactRowInfo2>
          </ContactRow>
          <ContactRow
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ContactRowName>Biuro projektów:</ContactRowName>

            <ContactRowInfo>
              {this.props.data.strapiContactPage.Design_office_name}
              <a
                href={`tel:${this.props.data.strapiContactPage.Design_office_tel}`}
              >
                {this.props.data.strapiContactPage.Design_office_tel}
              </a>
            </ContactRowInfo>
          </ContactRow>
          <ContactRow
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ContactRowName>Biuro:</ContactRowName>

            <ContactRowInfo>
              {this.props.data.strapiContactPage.Office_tel.map((document) => (
                <a href={`tel:${document.Tel}`}>{document.Tel}</a>
              ))}
            </ContactRowInfo>
          </ContactRow>
          <ContactRow
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ContactRowName>Godziny otwarcia:</ContactRowName>

            <HoursPosWrapper>
              {this.props.data.strapiContactPage.Opening_hours.map(
                (document) => (
                  <HoursPos>
                    <HoursText>{document.Day}</HoursText>
                    <HoursText>{document.Hours}</HoursText>
                  </HoursPos>
                )
              )}
            </HoursPosWrapper>
          </ContactRow>
          <ContactRow
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ContactRowName className="noMobile"></ContactRowName>

            <ContactRowInfo>
              <a
                target="blank"
                href={`${this.props.data.strapiContactPage.Rodo_pdf.publicURL}`}
              >
                Klauzula informacyjna RODO [PDF] <BtnArrowSvg />
              </a>
            </ContactRowInfo>
          </ContactRow>
        </Wrapper>
      </>
    );
  }
}

export const KontaktQuery = graphql`
  query KontaktQuery {
    strapiContactPage {
      Title
      SubTitle
      Owner_1_name
      Owner_1_tel
      Owner_2_name
      Owner_2_tel
      Design_office_name
      Design_office_tel
      Office_tel {
        Tel
      }
      Opening_hours {
        Day
        Hours
      }
      Rodo_pdf {
        publicURL
      }
      SEO {
        Description
        Title
      }
    }
    strapiContactInformation {
      Mail
    }
  }
`;

export default KontaktPage;
